import React from "react"
import dribbble from '../../images/Dribbble.svg'
import medium from '../../images/Medium.svg'
import linkedIn from '../../images/LinkedIn.svg'
import './footer.scss'


const Footer = (props) => {
	return (
		<div className="footer">
			<a href="https://medium.com/@cameron_sagey" target="_blank" rel="noreferrer" className="footer__icon">
				<img width="20" height="20" src={medium} alt="Medium"/>
			</a>
			<div className="footer__line"/>
			<a href="https://dribbble.com/cameronsagey" target="_blank" rel="noreferrer" className="footer__icon">
				<img width="20" height="20" src={dribbble} alt="Dribbble"/>
			</a>
			<div className="footer__line"/>
			<a href="https://www.linkedin.com/in/cameron-sagey" target="_blank" rel="noreferrer" className="footer__icon">
				<img width="20" height="20" src={linkedIn} alt="LinkedIn"/>
			</a>
		</div>
	)
}

export default Footer