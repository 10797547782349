import React, {memo} from "react";
import {motion} from 'framer-motion'
import './hero.scss'
import {durations} from '../global/variants'

const containerVariants = (oldRoute) => ({
	animate: {
		transition: {
			staggerChildren: .03,
			delayChildren: oldRoute ? durations.default : durations.first
		}
	}
})
const childVariants = {
	initial: {
		opacity: 0,
		y: 100,
		skewY: '10deg'
	},
	animate: {
		opacity: 1,
		y: 0,
		skewY: '0deg',
		transition: {
			opacity: {
				type: 'tween',
				ease: 'easeOut',
				duration: .5,
			},
			default: {
				type: 'spring',
				damping: 10,
				mass: .9,
				stiffness: 50
			},
		}
	}
}


const Hero = (props) => {

	const letters = [...props.headline]

	// console.log("Hero")

	return (
		<motion.div 
			className="hero"
			initial="initial"
			animate="animate"
			variants={containerVariants(props.oldRoute)}
		>
			<h1 key={'headline'} className="hero__headline">
			{letters.map((letter, i) => (
				<motion.span
					key={i}
					initial="initial"
					variants={childVariants}
				>{letter}</motion.span>
			))}
			</h1>
			<motion.div key={'underscore'} variants={childVariants} className="hero__underscore"/>
			<motion.span key={'left'} variants={childVariants} className="hero__left-text">{props.leftText}</motion.span>
			<motion.span key={'right'} variants={childVariants} className="hero__right-text">{props.rightText}</motion.span>
		</motion.div>
	)
}

export default memo(Hero)